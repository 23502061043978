'use client'
import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DEFAULT_EMBEDDED_CHATBOT_THEME_CONFIG, DEFAULT_THEME } from '../constant'
import ChatbotStyleConfig from '../components/chatbot-style-config'
import s from './index.module.css'
import i18n from '@/i18n/i18next-config'
import type { AppDetailResponse } from '@/models/app'
import type { ConfigParams } from '@/app/components/app/overview/settings/index'
import Button from '@/app/components/base/button'
import { useToastContext } from '@/app/components/base/toast'
import { cropImageToSquare, dataURLtoFile, imageUpload } from '@/app/components/base/image-uploader/utils'
import { DefaultLogo } from '@/app/components/base/icons/src/public/common'
import { UploadCloudNew } from '@/app/components/base/icons/src/vender/line/general'
import { useProviderContext } from '@/context/provider-context'
import type { EmbeddedChatbotThemeConfig } from '@/types/app'

type BasicInfoType = {
  appInfo: AppDetailResponse | null
  onSave?: (params: ConfigParams) => Promise<void>
  onCancel?: () => void
}

const ALLOW_FILE_EXTENSIONS = ['jpeg', 'png', 'jpg', 'webp']

const THEME_COLOR_REG = /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/

const BasicInfo: FC<BasicInfoType> = ({ appInfo, onSave, onCancel }) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  const { name } = appInfo ?? {}
  const site = appInfo?.site ?? { ...DEFAULT_THEME, embedded_chatbot_theme_config: { ...DEFAULT_EMBEDDED_CHATBOT_THEME_CONFIG } }
  if (site.unixyz_background && !site.unixyz_background.startsWith('#'))
    site.unixyz_background = `#${site.unixyz_background}`

  const [inputInfo, setInputInfo] = useState({ title: name })
  const [cropFile, setCropFile] = useState<File>()
  const [logoUrl, setLogoUrl] = useState('')
  const [uploadProgress, setUploadProgress] = useState(0)
  const [themeColor, setThemeColor] = useState(site.unixyz_background ?? DEFAULT_THEME.unixyz_background)
  const [preThemeColor, setPreThemeColor] = useState(site.unixyz_background ?? DEFAULT_THEME.unixyz_background) // 上次的颜色

  const { notify } = useToastContext()
  const { workspaceLimitData } = useProviderContext()

  const [embeddedChatbotThemeConfig, setEmbeddedChatbotThemeConfig] = useState<EmbeddedChatbotThemeConfig>(site.embedded_chatbot_theme_config ?? DEFAULT_EMBEDDED_CHATBOT_THEME_CONFIG)

  const handleUpdate = async () => {
    if (logoUrl && cropFile) {
      // 表示有上传
      imageUpload({
        file: cropFile,
        onProgressCallback: (progress) => {
          setUploadProgress(progress)
        },
        onSuccessCallback: async (res) => {
          setUploadProgress(100)

          const params = {
            title: inputInfo.title,
            unixyz_background: themeColor,
            unixyz_icon: res.id,
            embedded_chatbot_theme_config: embeddedChatbotThemeConfig,
          }
          await onSave?.(params as ConfigParams)
          setCropFile(undefined)
        },
        onErrorCallback: () => {
          notify({ type: 'error', message: systemT('common.imageUploader.uploadFromComputerUploadError') })
          setUploadProgress(-1)
        },
      }, false, '/apps/site/unixyz-icon/upload')
    }
    else {
      const params = {
        title: inputInfo.title,
        unixyz_background: themeColor,
        unixyz_icon: !logoUrl ? '' : site.unixyz_icon,
        embedded_chatbot_theme_config: embeddedChatbotThemeConfig,
      }
      await onSave?.(params as ConfigParams)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file)
      return

    if (file.size > 500 * 1024) { // 500kb
      notify({ type: 'error', message: systemT('custom.chatbot.changeLogoTip') })
      return
    }

    cropImageToSquare(file).then((dataUrl) => {
      // 在这里，你可以使用dataUrl来显示、下载或发送到服务器
      setLogoUrl(dataUrl)
      const afterCropFile = dataURLtoFile(dataUrl, file.name)
      setCropFile(afterCropFile)
    }).catch((error) => {
      console.error('裁剪图片', error)
      setLogoUrl('')
      setCropFile(undefined)
    })
  }

  useEffect(() => {
    if (site.unixyz_icon && !logoUrl) {
      // 获取logo url
      setLogoUrl(`/files/apps/${appInfo?.id}/app-icon`)
    }
  }, [site.unixyz_icon])

  return <div className={`h-full overflow-y-auto ${s.basicInfoContainer}`}>
    <div className={`font-medium ${s.settingTitle} text-gray-900`}>{systemT('appOverview.overview.appInfo.settings.webName')}</div>
    <div className='flex mt-2'>
      <input className={`flex-grow rounded-lg h-10 box-border px-3 ${s.projectName} bg-gray-100 appearance-none border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600`}
        value={inputInfo.title}
        onChange={(e) => { setInputInfo({ title: e.target.value }) }}
        placeholder={systemT('app.newApp.appNamePlaceholder') || ''}
      />
    </div>
    {workspaceLimitData?.custom_icon && (<div className='mt-4'>
      <div className={`font-medium ${s.settingTitle} text-gray-900 mb-2`}>{systemT('appOverview.overview.appInfo.settings.webLogo')}</div>
      <div>
        <div className='w-10 h-10 rounded-xl relative'>
          { logoUrl ? <img src={logoUrl} className='rounded-xl' alt="" /> : <DefaultLogo className='rounded-xl'></DefaultLogo>}
        </div>
        <div className='flex mt-2 items-center'>
          <Button
            className={`
              relative bg-white !text-sm !text-gray-500
            `}
          >
            <UploadCloudNew className='mr-1 w-4 h-4' />
            {systemT('custom.upload')}
            <input
              className='absolute block inset-0 opacity-0 text-[0] w-full cursor-pointer'
              onClick={e => (e.target as HTMLInputElement).value = ''}
              type='file'
              accept={ALLOW_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
              onChange={handleChange}
            />
          </Button>
          {
            logoUrl
              ? (
                <>
                  <div className='w-px h-5 bg-gray-200 mx-2'></div>
                  <Button className='!text-sm !text-gray-500' onClick={() => { setLogoUrl('') }}>
                    {systemT('custom.restore')}
                  </Button>
                </>
              )
              : null
          }
        </div>
        <div className='h-9 flex items-center text-xs text-uni-text-13'>{systemT('custom.chatbot.changeLogoTip')}</div>
      </div>
      <div className={`font-medium ${s.settingTitle} text-gray-900 mt-4 mb-2`}>{systemT('appOverview.overview.appInfo.settings.webThemeColor')}</div>
      <div className='relative w-[122px]'>
        <input className={`w-full rounded-lg h-10 box-border pr-3 pl-11 ${s.projectName} bg-gray-100 appearance-none border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600`}
          value={themeColor}
          onChange={(e) => { setThemeColor(e.target.value) }}
          onBlur={(e) => {
            const currentValue = e.target.value
            if (THEME_COLOR_REG.test(currentValue)) {
              setPreThemeColor(currentValue.includes('#') ? currentValue : `#${currentValue}`)
              setThemeColor(currentValue.includes('#') ? currentValue : `#${currentValue}`)
            }
            else {
              notify({ type: 'error', message: systemT('app.newApp.appThemeColorErrorTip') })
              setThemeColor(preThemeColor)
            }
          }}
          placeholder={systemT('app.newApp.appThemeColorPlaceholder') || ''}
        />
        <div className='w-6 h-6 rounded-full absolute top-2 left-3' style={{ backgroundColor: preThemeColor }}></div>
      </div>
      <ChatbotStyleConfig styleConfig={embeddedChatbotThemeConfig} onChange={(newConfig: EmbeddedChatbotThemeConfig) => setEmbeddedChatbotThemeConfig(newConfig)} />
    </div>)}
    <div className='absolute bottom-6 right-6'>
      <Button className="mr-2" onClick={() => { onCancel?.() }}>{systemT('common.operation.cancel')}</Button>
      <Button variant='primary' onClick={handleUpdate}>{systemT('common.operation.save')}</Button>
    </div>
  </div>
}

export default React.memo(BasicInfo)
