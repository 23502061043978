import { EMBEDDED_CHATBOT_POSITION } from '@/types/app'
export const DEFAULT_THEME = { unixyz_background: '#5C5262', unixyz_icon: '' }

export const DEFAULT_EMBEDDED_CHATBOT_THEME_CONFIG = {
  // 按钮大小
  unixyz_button_pc_size: 60,
  unixyz_button_mobile_size: 60,
  // 按钮圆角
  unixyz_button_pc_radius: 12,
  unixyz_button_mobile_radius: 12,
  // 按钮位置
  unixyz_button_pc_position: EMBEDDED_CHATBOT_POSITION.RIGHT,
  unixyz_button_mobile_position: EMBEDDED_CHATBOT_POSITION.RIGHT,
  // 按钮位置距左or距右距离
  unixyz_button_pc_side_boundary_gap: 16,
  unixyz_button_pc_bottom_boundary_gap: 16,
  // 按钮位置底部距离
  unixyz_button_mobile_side_boundary_gap: 16,
  unixyz_button_mobile_bottom_boundary_gap: 16,
}
