'use client'
import type { FC } from 'react'
import React, { useState } from 'react'
import s from './style.module.css'
import cn from '@/utils/classnames'
import ImagePreview from '@/app/components/base/image-uploader/image-preview'
import { type VisionFile } from '@/types/app'
import { getFileSize } from '@/utils/format'

type Props = {
  customTheme?: {
    unixyz_icon: string
    unixyz_background: string
  }
  allFiles: Pick<VisionFile, 'url' | 'type' | 'name' | 'size'>[]
}

const getWidthStyle = (imgNum: number) => {
  if (imgNum === 1) {
    return {
      maxWidth: '100%',
    }
  }

  if (imgNum === 2 || imgNum === 4) {
    return {
      width: 'calc(50% - 4px)',
    }
  }

  return {
    width: 'calc(33.3333% - 5.3333px)',
  }
}

const ImageGalleryUnixyz: FC<Props> = ({
  customTheme,
  allFiles,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('')
  const [previewMediaType, setPreviewMediaType] = useState('image')

  const imgNum = allFiles.length
  const imgStyle = getWidthStyle(imgNum)
  return (
    <div className={cn(s[`img-${imgNum}`], 'flex flex-col')}>
      {allFiles.map((file, index) => {
        if (file.type === 'video') {
          return (
            <video
              key={index}
              // style={imgStyle}
              className={s.item}
              onClick={() => {
                setImagePreviewUrl(file.url)
                setPreviewMediaType('video')
              }}
              onError={e => e.currentTarget.remove()}
            >
              <source src={file.url} type="video/mp4" />
            </video>
          )
        }
        else if (file.type === 'image') {
          return (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              key={index}
              className={s.item}
              // style={imgStyle}
              src={file.url}
              alt=''
              onClick={() => {
                setImagePreviewUrl(file.url)
                setPreviewMediaType('image')
              }}
              onError={e => e.currentTarget.remove()}
            />
          )
        }
        else if (file.type === 'package') {
          return (
            <div key={index} className='flex items-center w-full bg-white rounded-lg border-uni-border-6 px-3 py-2 mb-2 last:mb-0'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M9.88584 5.17144L6.11457 8.94266C5.85422 9.20299 5.85422 9.62512 6.11457 9.88546C6.37492 10.1459 6.79704 10.1459 7.05737 9.88546L10.8286 6.11424C11.6096 5.3332 11.6096 4.06687 10.8286 3.28582C10.0476 2.50477 8.78124 2.50477 8.00017 3.28582L4.22895 7.05706C2.92721 8.35879 2.92721 10.4693 4.22895 11.7711C5.5307 13.0729 7.64124 13.0729 8.94297 11.7711L12.7142 7.99986L13.657 8.94266L9.88584 12.7139C8.06337 14.5363 5.10859 14.5363 3.28615 12.7139C1.4637 10.8915 1.4637 7.93672 3.28615 6.11424L7.05737 2.34301C8.35911 1.04126 10.4697 1.04126 11.7714 2.34301C13.0732 3.64476 13.0732 5.7553 11.7714 7.05706L8.00017 10.8283C7.21917 11.6093 5.95281 11.6093 5.17177 10.8283C4.39071 10.0473 4.39071 8.78092 5.17177 7.99986L8.94297 4.22863L9.88584 5.17144Z" fill={customTheme?.unixyz_background}/>
              </svg>
              <span className='ml-1 text-uni-text-10 text-sm font-normal text-ellipsis overflow-hidden whitespace-nowrap flex-1' title={file.name}>{file.name}</span>
              <span className='ml-3 text-uni-text-3 text-xs'>{getFileSize(file?.size ?? 0)}</span>
            </div>
          )
        }
        else {
          return null
        }
      })}
      {
        imagePreviewUrl && (
          <ImagePreview
            url={imagePreviewUrl}
            onCancel={() => {
              setImagePreviewUrl('')
              setPreviewMediaType('image')
            }}
            previewMediaType={previewMediaType}
          />
        )
      }
    </div>
  )
}

export default React.memo(ImageGalleryUnixyz)
