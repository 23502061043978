'use client'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { RiCloseLine } from '@remixicon/react'
import IntegrationsPage from './integrations'
import s from './style.module.css'
import BasicInfo from './basic-info'
import SafetyInfo from './safety'
import Modal from '@/app/components/base/modal'
import {
  Database03,
  Webhooks,
} from '@/app/components/base/icons/src/vender/line/development'
import { Database03 as Database03Solid } from '@/app/components/base/icons/src/vender/solid/development'
import { InfoCircleNew } from '@/app/components/base/icons/src/vender/line/general'
import useBreakpoints, { MediaType } from '@/hooks/use-breakpoints'
import i18n from '@/i18n/i18next-config'
import { InfoCircle as InfoCircleSolid } from '@/app/components/base/icons/src/vender/solid/general'
import type { AppDetailResponse } from '@/models/app'
import type { ConfigParams } from '@/app/components/app/overview/settings/index'

const iconClassName = `
  w-4 h-4 ml-3 mr-2
`

const scrolledClassName = `
  border-b shadow-xs bg-white/[.98]
`

type IAccountSettingProps = {
  appInfo: AppDetailResponse | null
  onCancel: () => void
  activeTab?: string
  onSave?: (params: ConfigParams) => Promise<void>
  onRefreshAppDetail: (() => Promise<void>) | VoidFunction
}

type GroupItem = {
  key: string
  name: string
  description?: string
  icon: JSX.Element
  activeIcon: JSX.Element
}

export default function NewAppSetting({
  appInfo,
  onCancel,
  activeTab = 'basic-info',
  onSave,
  onRefreshAppDetail,
}: IAccountSettingProps) {
  const [activeMenu, setActiveMenu] = useState(activeTab)
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const settingGroupItems = (() => {
    return [
      {
        key: 'basic-info',
        name: systemT('common.settings.basicInfo'),
        icon: <InfoCircleNew className={iconClassName} />,
        activeIcon: <InfoCircleSolid className={iconClassName} />,
      },
      {
        key: 'safety',
        name: systemT('common.settings.safety'),
        icon: <Database03 className={iconClassName} />,
        activeIcon: <Database03Solid className={iconClassName} />,
      },
      {
        key: 'integrations',
        name: systemT('common.settings.integrations'),
        icon: <Webhooks className={iconClassName} />,
        activeIcon: <Webhooks className={iconClassName} />,
      },
    ].filter(item => !!item.key) as GroupItem[]
  })()

  const media = useBreakpoints()
  const isMobile = media === MediaType.mobile

  const menuItems = [
    {
      key: 'setting-group',
      items: settingGroupItems,
    },
  ]
  const scrollRef = useRef<HTMLDivElement>(null)
  const [scrolled, setScrolled] = useState(false)
  useEffect(() => {
    const targetElement = scrollRef.current
    const scrollHandle = (e: Event) => {
      const userScrolled = (e.target as HTMLDivElement).scrollTop > 0
      setScrolled(userScrolled)
    }
    targetElement?.addEventListener('scroll', scrollHandle)
    return () => {
      targetElement?.removeEventListener('scroll', scrollHandle)
    }
  }, [])

  const activeItem = [...menuItems[0].items].find(item => item.key === activeMenu)

  return (
    <Modal
      isShow
      onClose={() => { }}
      className={s.modal}
      wrapperClassName='!z-20 pt-[60px]'
    >
      <div className='flex'>
        <div className='w-[44px] sm:w-[160px] px-[1px] py-4 sm:p-4 border border-gray-100 shrink-0 sm:shrink-1 flex flex-col items-center sm:items-start'>
          <div className='mb-8 ml-0 sm:ml-2 text-sm sm:text-base font-medium leading-6 text-gray-900'>{systemT('common.userProfile.settings')}</div>
          <div className='w-full'>
            {
              menuItems.map(menuItem => (
                <div key={menuItem.key} className='mb-4'>
                  <div>
                    {
                      menuItem.items.map(item => (
                        <div
                          key={item.key}
                          className={`
                            flex items-center h-[37px] mb-[2px] text-sm cursor-pointer rounded-lg
                            ${activeMenu === item.key ? 'font-semibold text-primary-600 bg-uni-fill-7' : 'font-light text-gray-700'}
                          `}
                          title={item.name}
                          onClick={() => setActiveMenu(item.key)}
                        >
                          {activeMenu === item.key ? item.activeIcon : item.icon}
                          {!isMobile && <div className='truncate'>{item.name}</div>}
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div ref={scrollRef} className='relative flex-1 h-[540px] pb-4 overflow-y-auto'>
          <div className={cn('sticky top-0 px-6 py-4 flex items-center h-14 mb-4 bg-white text-base font-medium text-gray-900 z-20', scrolled && scrolledClassName)}>
            <div className='shrink-0'>{activeItem?.name}</div>
            {
              activeItem?.description && (
                <div className='shrink-0 ml-2 text-xs text-gray-600'>{activeItem?.description}</div>
              )
            }
            <div className='grow flex justify-end'>
              <div className='flex items-center justify-center -mr-4 w-6 h-6 cursor-pointer' onClick={onCancel}>
                <RiCloseLine className='w-4 h-4 text-gray-400' />
              </div>
            </div>
          </div>
          <div className='px-6 sm:px-6 pt-2 h-[calc(100%-72px)]'>
            {activeMenu === 'basic-info' && <BasicInfo appInfo={appInfo} onSave={onSave} onCancel={onCancel} />}
            {activeMenu === 'safety' && <SafetyInfo appInfo={appInfo} onSave={onSave} onCancel={onCancel} />}
            {activeMenu === 'integrations' && <IntegrationsPage appInfo={appInfo} onRefreshAppDetail={onRefreshAppDetail} />}
          </div>
        </div>
      </div>
    </Modal>
  )
}
