import { post } from './base'
import { type ZendeskPostConfig } from '@/types/reachOutTeam'

export const updateShopifyBindStatus = (body: Record<string, any>) => {
  return post<{ name: string; bind_status: string; app_id: string }>('/apps/shopify_bind_status', {
    body,
  })
}

export const bindShopifyShopWithChatbot = (url: string, body: Record<string, any>) => {
  return post(url, {
    body,
  })
}

export const bindZendeskAccount = (url: string, body: ZendeskPostConfig) => {
  return post(url, {
    body,
  })
}

export const activateZendesk = (body: Record<string, any>) => {
  return post('zendesk/api/activate', {
    body,
  }, {
    isPublicAPI: true,
    withoutPrefix: true,
  })
}
