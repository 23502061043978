'use client'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputNumber, Select } from 'antd'
import s from '../basic-info/index.module.css'
import i18n from '@/i18n/i18next-config'
import classNames from '@/utils/classnames'
import type { EmbeddedChatbotThemeConfig } from '@/types/app'
import { EMBEDDED_CHATBOT_POSITION } from '@/types/app'

const ChatbotStyleConfig: FC<{ styleConfig: EmbeddedChatbotThemeConfig; onChange: (config: EmbeddedChatbotThemeConfig) => void }> = ({ styleConfig, onChange }) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const POSITION_OPTION = [
    {
      value: EMBEDDED_CHATBOT_POSITION.LEFT,
      label: systemT('appOverview.overview.appInfo.settings.buttonPosition.left'),
    },
    {
      value: EMBEDDED_CHATBOT_POSITION.RIGHT,
      label: systemT('appOverview.overview.appInfo.settings.buttonPosition.right'),
    },
  ]

  const config = [
    {
      type: 'pc',
    },
    {
      type: 'mobile',
    },
  ]

  const [styleConfigValue, setStyleConfigValue] = useState({ ...styleConfig })

  useEffect(() => {
    onChange(styleConfigValue)
  }, [onChange, styleConfigValue])

  return (
    <div>
      <div className={`font-medium ${s.settingTitle} text-gray-900 mt-4 mb-2`}>{systemT('appOverview.overview.appInfo.settings.buttonShape.label')}</div>
      <div className='flex'>
        <div className='flex flex-col justify-center mr-3'>
          <span className='text-xs text-gray-500 mb-1'>{systemT('appOverview.overview.appInfo.settings.buttonShape.size')}</span>
          <InputNumber
            className='w-24 h-9'
            suffix='px'
            type='number'
            min={10}
            max={200}
            value={styleConfigValue.unixyz_button_pc_size}
            onChange={(value) => {
              setStyleConfigValue({
                ...styleConfigValue,
                unixyz_button_mobile_size: value!,
                unixyz_button_pc_size: value!,
              })
            }}
          />
        </div>
        <div className='flex flex-col justify-center'>
          <span className='text-xs text-gray-500 mb-1'>{systemT('appOverview.overview.appInfo.settings.buttonShape.radius')}</span>
          <InputNumber
            className='w-24 h-9'
            suffix='px'
            type='number'
            min={0}
            max={9999}
            value={styleConfigValue.unixyz_button_pc_radius}
            onChange={(value) => {
              setStyleConfigValue({
                ...styleConfigValue,
                unixyz_button_mobile_radius: value!,
                unixyz_button_pc_radius: value!,
              })
            }}
          />
        </div>
      </div>
      <div className={`font-medium ${s.settingTitle} text-gray-900 mt-4 mb-2`}>{systemT('appOverview.overview.appInfo.settings.buttonPosition.label')}</div>
      <div className='mb-6'>
        {
          config.map((configItem) => {
            const isPC = configItem.type === 'pc'
            return (
              <div key={configItem.type} className={classNames('flex items-center', isPC && 'mb-2')}>
                <div className={`flex justify-center mr-3 ${isPC ? 'mt-6' : 'mt-2'}`}>
                  {
                    isPC
                      ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.08579 2.08579C2.46086 1.71071 2.96957 1.5 3.5 1.5H12.5C13.0304 1.5 13.5391 1.71071 13.9142 2.08579C14.2893 2.46086 14.5 2.96957 14.5 3.5V10C14.5 10.5304 14.2893 11.0391 13.9142 11.4142C13.5391 11.7893 13.0304 12 12.5 12H10.5V12.1713C10.5 12.3684 10.5388 12.5636 10.6142 12.7456C10.6896 12.9277 10.8001 13.0931 10.9395 13.2324L11.3536 13.6464C11.4966 13.7894 11.5393 14.0045 11.4619 14.1913C11.3846 14.3782 11.2022 14.5 11 14.5H5C4.79777 14.5 4.61545 14.3782 4.53806 14.1913C4.46067 14.0045 4.50345 13.7894 4.64645 13.6464L5.06045 13.2324C5.1998 13.0931 5.3104 12.9277 5.38581 12.7456C5.46122 12.5636 5.50002 12.3684 5.5 12.1714V12H3.5C2.96957 12 2.46086 11.7893 2.08579 11.4142C1.71071 11.0391 1.5 10.5304 1.5 10V3.5C1.5 2.96957 1.71071 2.46086 2.08579 2.08579ZM2.5 3.5V8C2.5 8.26522 2.60536 8.51957 2.79289 8.70711C2.98043 8.89464 3.23478 9 3.5 9H12.5C12.7652 9 13.0196 8.89464 13.2071 8.70711C13.3946 8.51957 13.5 8.26522 13.5 8V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H3.5C3.23478 2.5 2.98043 2.60536 2.79289 2.79289C2.60536 2.98043 2.5 3.23478 2.5 3.5ZM13.5 9.73206C13.1985 9.90609 12.8541 10 12.5 10H3.5C3.14588 10 2.80145 9.90609 2.5 9.73206V10C2.5 10.2652 2.60536 10.5196 2.79289 10.7071C2.98043 10.8946 3.23478 11 3.5 11H12.5C12.7652 11 13.0196 10.8946 13.2071 10.7071C13.3946 10.5196 13.5 10.2652 13.5 10V9.73206ZM9.5 12H6.5V12.1713C6.5 12.1713 6.5 12.1713 6.5 12.1713C6.50003 12.4997 6.43536 12.8249 6.30968 13.1283C6.25611 13.2577 6.19189 13.382 6.11785 13.5H9.88215C9.80811 13.382 9.74389 13.2577 9.69032 13.1283C9.56464 12.8249 9.49997 12.4997 9.5 12.1713C9.5 12.1713 9.5 12.1714 9.5 12.1713V12Z" fill="#6B7280"/>
                      </svg>
                      : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.08579 1.08579C4.46086 0.710714 4.96957 0.5 5.5 0.5H10.5C11.0304 0.5 11.5391 0.710714 11.9142 1.08579C12.2893 1.46086 12.5 1.96957 12.5 2.5V13.5C12.5 14.0304 12.2893 14.5391 11.9142 14.9142C11.5391 15.2893 11.0304 15.5 10.5 15.5H5.5C4.96957 15.5 4.46086 15.2893 4.08579 14.9142C3.71071 14.5391 3.5 14.0304 3.5 13.5V2.5C3.5 1.96957 3.71071 1.46086 4.08579 1.08579ZM5.5 1.5C5.23478 1.5 4.98043 1.60536 4.79289 1.79289C4.60536 1.98043 4.5 2.23478 4.5 2.5V13.5C4.5 13.7652 4.60536 14.0196 4.79289 14.2071C4.98043 14.3946 5.23478 14.5 5.5 14.5H10.5C10.7652 14.5 11.0196 14.3946 11.2071 14.2071C11.3946 14.0196 11.5 13.7652 11.5 13.5V2.5C11.5 2.23478 11.3946 1.98043 11.2071 1.79289C11.0196 1.60536 10.7652 1.5 10.5 1.5H9.5V2C9.5 2.27614 9.27614 2.5 9 2.5H7C6.72386 2.5 6.5 2.27614 6.5 2V1.5H5.5ZM6.5 13.5C6.5 13.2239 6.72386 13 7 13H9C9.27614 13 9.5 13.2239 9.5 13.5C9.5 13.7761 9.27614 14 9 14H7C6.72386 14 6.5 13.7761 6.5 13.5Z" fill="#6B7280"/>
                      </svg>
                  }
                  <span className='text-xs text-gray-500 mb-1 ml-1'>{isPC ? systemT('appOverview.overview.appInfo.settings.buttonPosition.pc') : systemT('appOverview.overview.appInfo.settings.buttonPosition.mobile')}</span>
                </div>
                <div className='flex flex-col justify-center mr-3'>
                  {isPC && (
                    <span className='text-xs text-gray-500 mb-1'>{systemT('appOverview.overview.appInfo.settings.buttonPosition.position')}</span>
                  )}
                  <Select
                    className='w-24 h-9'
                    value={isPC ? styleConfigValue.unixyz_button_pc_position : styleConfigValue.unixyz_button_mobile_position}
                    onChange={(value) => {
                      if (isPC) {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_pc_position: value,
                        })
                      }
                      else {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_mobile_position: value,
                        })
                      }
                    }}
                    allowClear={false}
                    options={POSITION_OPTION}
                    showSearch={false}
                  />
                </div>
                <div className='flex flex-col justify-center mr-3'>
                  {isPC && (
                    <span className='text-xs text-gray-500 mb-1'>{systemT('appOverview.overview.appInfo.settings.buttonPosition.xGap')}</span>
                  )}
                  <InputNumber
                    className='w-24 h-9'
                    suffix='px'
                    min={0}
                    value={isPC ? styleConfigValue.unixyz_button_pc_side_boundary_gap : styleConfigValue.unixyz_button_mobile_side_boundary_gap}
                    onChange={(value) => {
                      if (isPC) {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_pc_side_boundary_gap: value!,
                        })
                      }
                      else {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_mobile_side_boundary_gap: value!,
                        })
                      }
                    }}
                  />
                </div>
                <div className='flex flex-col justify-center'>
                  {isPC && (
                    <span className='text-xs text-gray-500 mb-1'>{systemT('appOverview.overview.appInfo.settings.buttonPosition.bottom')}</span>
                  )}
                  <InputNumber
                    className='w-24 h-9'
                    suffix='px'
                    min={0}
                    value={isPC ? styleConfigValue.unixyz_button_pc_bottom_boundary_gap : styleConfigValue.unixyz_button_mobile_bottom_boundary_gap}
                    onChange={(value) => {
                      if (isPC) {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_pc_bottom_boundary_gap: value!,
                        })
                      }
                      else {
                        setStyleConfigValue({
                          ...styleConfigValue,
                          unixyz_button_mobile_bottom_boundary_gap: value!,
                        })
                      }
                    }}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default React.memo(ChatbotStyleConfig)
