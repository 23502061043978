'use client'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useState } from 'react'
import s from './index.module.css'
import ZendeskConfigSetDialog from './zendeskConfigDialog'
import i18n from '@/i18n/i18next-config'
import InstallShopifyAppGuidance from '@/app/components/app/overview/settings/integrations/shopifyAppInstallGuidance'
import { useProviderContext } from '@/context/provider-context'
import type { AppDetailResponse } from '@/models/app'
import { BotType } from '@/types/app'

type IntegrationItem = {
  provider: 'shopify' | 'zendesk'
  is_bound: boolean
  name?: string
  link?: string
  workspace_source_enable: boolean
}

type IntegrationsType = {
  appInfo: AppDetailResponse | null
  onRefreshAppDetail: (() => Promise<void>) | VoidFunction
}

export default function IntegrationsPage({ appInfo, onRefreshAppDetail }: IntegrationsType) {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  const { workspaceLimitData } = useProviderContext()
  const [showGuidanceInstallDialog, setShowGuidanceInstallDialog] = useState(false)
  const [showZendeskConfigSetDialog, setShowZendeskConfigSetDialog] = useState(false)

  const { shopify_config, zendesk_config, bot_type: botType = BotType.ChatBot } = appInfo || {}
  const integrateMap = {
    shopify: {
      description: systemT('common.integrations.shopify.description'),
    },
    zendesk: {
      description: systemT('common.integrations.zendesk.description'),
    },
  }

  const integrates: Array<IntegrationItem> = [
    {
      provider: 'shopify',
      is_bound: !!shopify_config,
      name: shopify_config?.name ?? '',
      link: shopify_config?.myshopify_domain ?? '',
      workspace_source_enable: workspaceLimitData.enable_shopify,
    },
    {
      provider: 'zendesk',
      is_bound: !!zendesk_config,
      name: '',
      link: '',
      workspace_source_enable: workspaceLimitData.enable_zendesk,
    },
  ]

  const handleClick = (integrate: IntegrationItem) => {
    if (!integrate.workspace_source_enable)
      return
    if (integrate.provider === 'shopify') {
      if (integrate.is_bound) {
        // 跳转到商店
        window.open(`https://${integrate.link}`, '_blank')
      }
      else {
        // 指引安装插件
        setShowGuidanceInstallDialog(true)
      }
    }
    else if (integrate.provider === 'zendesk') {
      // zendesk
      setShowZendeskConfigSetDialog(true)
    }
  }

  return (
    <>
      <div className='mb-8 flex flex-wrap'>
        {
          integrates.map(integrate => (
            <div key={integrate.provider} className='w-[248px] rounded-xl bg-gray-50 shadow-xs border border-solid border-black/5 py-3 px-4 last:ml-4'>
              <div className={classNames(s[`${integrate.provider}-icon`], 'mb-1 h-[30px]')}></div>
              <div className='leading-[18px] text-xs font-normal text-uni-text-13 mb-6'>{integrateMap[integrate.provider].description}</div>
              <div className={classNames('flex justify-center items-center rounded-md border border-gray-200 border-solid box-border h-[28px] w-[215px]', integrate.workspace_source_enable && 'cursor-pointer')} onClick={() => { handleClick(integrate) }}>
                {integrate.workspace_source_enable
                  ? integrate.is_bound
                    ? (
                      <div className='flex items-center py-1.5 px-3'>
                        <span className='rounded-[3px] border border-solid border-uni-border-7 bg-uni-fill-16 mr-1.5 w-2 h-2'></span>
                        <span className='text-xs font-medium text-black truncate max-w-[177px]' title={integrate.provider === 'shopify' ? integrate.name : ''}>{integrate.provider === 'shopify' ? integrate.name : systemT('common.integrations.common.setConfig')}</span>
                      </div>
                    )
                    : <span className='text-gray-700 text-xs font-medium'>{systemT('common.integrations.common.connect')}</span>
                  : <span className='text-gray-700 text-xs font-medium'>{systemT('common.integrations.common.upgradeVersion')}</span> }
              </div>
            </div>
          ))
        }
      </div>
      <InstallShopifyAppGuidance show={showGuidanceInstallDialog} onClose={() => setShowGuidanceInstallDialog(false)}></InstallShopifyAppGuidance>
      <ZendeskConfigSetDialog botType={botType} show={showZendeskConfigSetDialog} onClose={() => { setShowZendeskConfigSetDialog(false) }} config={zendesk_config} appId={appInfo?.id} onSaveCallback={() => onRefreshAppDetail()}></ZendeskConfigSetDialog>
    </>
  )
}
