import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useRef, useState } from 'react'
import Modal from '@/app/components/base/modal'
import i18n from '@/i18n/i18next-config'
import { type ZendeskConfig, type ZendeskPostConfig } from '@/types/reachOutTeam'
import Button from '@/app/components/base/button'
import { bindZendeskAccount } from '@/service/integrations'
import { BotType } from '@/types/app'
import InputCopy from '@/app/components/develop/secret-key/input-copy'
import { HelpCircle } from '@/app/components/base/icons/src/vender/line/general'
import Tooltip from '@/app/components/base/tooltip'
import Toast from '@/app/components/base/toast'

type ZendeskConfigSetDialogProps = {
  botType: BotType
  show: boolean
  onClose: () => void
  config?: ZendeskConfig
  appId?: string
  onSaveCallback: () => void
}
const UNCHANGETAG = '[__HIDDEN__]'

const ZendeskWebHookTagInput = ({ value = '' }) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  return (
    value
      ? <div className='flex flex-col gap-2 px-3 py-3 bg-gray-100 rounded-lg mt-[18px]'>
        <div className='flex items-center gap-1'>
          <div className={'text-sm text-gray-600'}>{systemT('common.integrations.zendesk.webhookTag')}</div>
          <Tooltip htmlContent={<div>
            {systemT('common.integrations.zendesk.webhookTagTip')}
          </div>} selector='config-ic-tooltip'>
            <HelpCircle className='w-[14px] h-[14px] text-gray-400' />
          </Tooltip>
        </div>
        <InputCopy value={value} className='w-full' />
      </div>
      : null
  )
}

export default function ZendeskConfigSetDialog({ botType, show, onClose, config, appId, onSaveCallback }: ZendeskConfigSetDialogProps) {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  const originConfig = useRef(config)
  const [zendeskConfig, setZendeskConfig] = useState<ZendeskPostConfig>({
    zendesk_config_domain: '',
    zendesk_config_client_id: '',
    zendesk_config_secret_id: '',
    zendesk_config_secret: '',
    zendesk_config_admin_email: '',
    zendesk_config_api_secret: '',
  })

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (config) {
      setZendeskConfig(config)
      originConfig.current = config
    }
  }, [config])

  const handleBind = async () => {
    const zendeskBindParams = {
      ...zendeskConfig,
      bot_type: botType,
    }
    if (originConfig.current) {
      const { zendesk_config_secret, zendesk_config_api_secret } = originConfig.current
      const {
        zendesk_config_secret: current_zendesk_config_secret,
        zendesk_config_api_secret: current_zendesk_config_api_secret,
      } = zendeskConfig

      if (zendesk_config_secret === current_zendesk_config_secret) {
        // 没有修改
        zendeskBindParams.zendesk_config_secret = UNCHANGETAG
      }

      if (zendesk_config_api_secret === current_zendesk_config_api_secret)
        zendeskBindParams.zendesk_config_api_secret = UNCHANGETAG
    }
    if (!loading) {
      setLoading(true)
      try {
        await bindZendeskAccount(`/apps/${appId}/zendesk/bind`, zendeskBindParams)
        Toast.notify({
          type: 'success',
          message: systemT('common.api.success'),
        })
        onSaveCallback()
      }
      finally {
        setLoading(false)
      }
    }
  }

  const saveDisabled = useMemo(() => {
    if (botType === BotType.ChatBot) {
      const { zendesk_config_domain, zendesk_config_client_id, zendesk_config_secret_id, zendesk_config_secret, zendesk_config_admin_email, zendesk_config_api_secret } = zendeskConfig
      return !(zendesk_config_domain && zendesk_config_client_id && zendesk_config_secret_id && zendesk_config_secret && zendesk_config_admin_email && zendesk_config_api_secret)
    }
    else if (botType === BotType.MailBot) {
      const { zendesk_config_domain, zendesk_config_admin_email, zendesk_config_api_secret } = zendeskConfig
      return !(zendesk_config_domain && zendesk_config_admin_email && zendesk_config_api_secret)
    }
    else {
      return true
    }
  }, [zendeskConfig])

  const CHATBOT_INPUT_CONFIG = [
    {
      key: 'zendesk_config_domain',
      label: systemT('common.integrations.zendesk.domain'),
      placeholder: 'https://yoursubdomain.zendesk.com',
      modifiable: false,
    },
    {
      key: 'zendesk_config_client_id',
      label: systemT('common.integrations.zendesk.clientID'),
      modifiable: false,
    },
    {
      key: 'zendesk_config_secret_id',
      label: systemT('common.integrations.zendesk.secretID'),
      modifiable: true,
    },
    {
      key: 'zendesk_config_secret',
      label: systemT('common.integrations.zendesk.secret'),
      modifiable: true,
    },
    {
      key: 'zendesk_config_admin_email',
      label: systemT('common.integrations.zendesk.adminEmail'),
      modifiable: true,
    },
    {
      key: 'zendesk_config_api_secret',
      label: systemT('common.integrations.zendesk.apiSecret'),
      modifiable: true,
    },
  ]

  const MAILBOT_INPUT_CONFIG = [
    {
      key: 'zendesk_config_domain',
      label: systemT('common.integrations.zendesk.domain'),
      placeholder: 'https://yoursubdomain.zendesk.com',
      modifiable: false,
    },
    {
      key: 'zendesk_config_admin_email',
      label: systemT('common.integrations.zendesk.adminEmail'),
      modifiable: true,
    },
    {
      key: 'zendesk_config_api_secret',
      label: systemT('common.integrations.zendesk.apiSecret'),
      modifiable: true,
    },
  ]

  const inputConfig = useMemo(() => {
    if (botType === BotType.ChatBot)
      return CHATBOT_INPUT_CONFIG

    if (botType === BotType.MailBot)
      return MAILBOT_INPUT_CONFIG

    return []
  }, [botType]) as Array<{
    key: keyof ZendeskPostConfig
    label: string
    modifiable: boolean
    placeholder?: string
  }>

  return (
    <Modal
      className='!w-[520px]'
      isShow={show}
      closable
      onClose={onClose}
      title={systemT('common.integrations.zendesk.configSetTitle')}
    >
      {/* 反显 webhook tag */}
      { botType === BotType.MailBot && <ZendeskWebHookTagInput value={config?.zendesk_config_webhook_tag} /> }

      {/* 信息编辑 */}
      {
        inputConfig.map(item => (
          <>
            <div key={item.key} className={'mt-[18px] mb-2 text-sm font-medium text-gray-900'}>{item.label}</div>
            <input
              className={'mb-5 box-border pt-2 pb-2 px-3 rounded-lg bg-gray-100 w-full h-10 text-gray-900 resize-none border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600'}
              value={zendeskConfig?.[item.key]}
              disabled={!!(originConfig.current?.[item.key]) && !item.modifiable}
              onChange={(e) => {
                setZendeskConfig({
                  ...zendeskConfig,
                  [item.key]: e.target.value.trim(),
                })
              }}
              placeholder={item.placeholder}
            />
          </>
        ))
      }

      <div className='flex justify-end'>
        <Button className='mr-2' onClick={onClose}>{systemT('common.operation.cancel')}</Button>
        <Button variant='primary' loading={loading} onClick={handleBind} disabled={saveDisabled}>{systemT('common.operation.save')}</Button>
      </div>
    </Modal>
  )
}
