import { useTranslation } from 'react-i18next'
import Link from 'next/link'
import Modal from '@/app/components/base/modal'
import i18n from '@/i18n/i18next-config'
import { Markdown } from '@/app/components/base/markdown'
import { JumpLink } from '@/app/components/base/icons/src/vender/solid/general'

type InstallShopifyAppGuidanceProps = {
  show: boolean
  onClose: () => void
}

export default function InstallShopifyAppGuidance({ show, onClose }: InstallShopifyAppGuidanceProps) {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })

  return (
    <Modal
      className='!w-[520px] h-[392px]'
      isShow={show}
      closable
      onClose={onClose}
      title={systemT('common.integrations.shopify.guidanceTitle')}
    >
      <div className='mt-[18px] h-[286px]'>
        <Markdown content={systemT('common.integrations.shopify.guidanceContent')}></Markdown>
      </div>
      <Link
        className='flex items-center text-xs font-medium text-uni-text-7 cursor-pointer'
        href='https://apps.shopify.com/elephant-ai'
        target='_blank' rel='noopener noreferrer'>
        <span className='mr-1'>{systemT('app.shopify.installLink')}</span>
        <JumpLink></JumpLink>
      </Link>
    </Modal>
  )
}
