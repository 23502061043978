'use client'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import s from './index.module.css'
import i18n from '@/i18n/i18next-config'
import type { AppDetailResponse } from '@/models/app'
import { type ConfigParams } from '@/app/components/app/overview/settings/newSetting'
import Button from '@/app/components/base/button'

type SafetyInfoType = {
  appInfo: AppDetailResponse | null
  onSave?: (params: ConfigParams) => Promise<void>
  onCancel?: () => void
}

const SafetyInfo: FC<SafetyInfoType> = ({ appInfo, onSave, onCancel }) => {
  const { t: systemT } = useTranslation(undefined, { i18n: i18n.systemI18n })
  let { white_list_websites } = appInfo?.site ?? {}
  if (!white_list_websites || (white_list_websites && white_list_websites.length === 0))
    white_list_websites = ['']

  // 允许使用的网站
  const [websiteWhiteList, setWebsiteWhiteList] = useState<string[]>(white_list_websites)
  const [isValidFail, setIsValidFail] = useState(false)

  const domainPattern = /^(http(s)?:\/\/)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const handleUpdate = async () => {
    const params = {
      white_list_websites: websiteWhiteList.filter(item => item),
    }
    await onSave?.(params as ConfigParams)
  }

  return <div className='h-full relative'>
    <div className={`mb-2 font-medium ${s.settingTitle} text-gray-900 `}>{systemT('appOverview.overview.appInfo.settings.website')}</div>
    <input
      className={`box-border pt-2 pb-2 px-3 rounded-lg bg-gray-100 w-full ${s.projectName} h-10 text-gray-900 resize-none border border-gray-200 hover:border-gray-300 focus:outline-none focus:ring-primary-600 focus:border-primary-600`}
      value={websiteWhiteList[0]}
      onChange={(e) => {
        setWebsiteWhiteList([e.target.value])
      }}
      onBlur={(e) => {
        if (e.target.value) {
          // 有填才做校验
          setIsValidFail(!domainPattern.test(e.target.value))
        }
        else {
          setIsValidFail(false)
        }
      }}
      placeholder={systemT('appOverview.overview.appInfo.settings.webSitePlaceholder') as string}
    />
    {isValidFail && (
      <div className='text-uni-text-11 text-xs font-normal mb-3 mt-2'>{systemT('appOverview.overview.appInfo.settings.webSiteValidateError')}</div>
    )}
    <div className='absolute bottom-2 right-0'>
      <Button className="mr-2" onClick={() => { onCancel?.() }}>{systemT('common.operation.cancel')}</Button>
      <Button variant='primary' onClick={handleUpdate}>{systemT('common.operation.save')}</Button>
    </div>
  </div>
}

export default React.memo(SafetyInfo)
